import styles from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={styles["page-footer"]}>
            <div className={"container " + styles['footer-container']}>
                <div className="row billAndMail justify-content-center">
                    <div className={styles.itemsbm + " col-12 col-md-6 text-center"}>
                        <h3 className={styles["footer-titles"]}><span>Contact Us</span></h3>
                        <p><a href={"mailto: info.rvspharma@gmail.com"}>info.rvspharma@gmail.com</a></p>
                        <p><a href={"tel: +918074023855"}>+91 8074023855</a></p>
                        <p><a href={"tel: 040-35954545"}>040-35954545</a></p>
                    </div>
                </div>
            </div>
            <hr/>
            <p className="end text-center">All rights reserved. Copyrights By RVS Pharma.</p>
            <p className={styles['end-dev'] + " text-center"}>Developed by Vasudeva, Mani Chandra, Vishal, Teja.</p>
        </footer>
    )
}

export default Footer;