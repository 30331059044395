import {Fragment, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import CardComponent from "../components/CardComponent";
import {collection, query, where, getDocs} from "firebase/firestore/lite";

import styles from './SearchPage.module.css'
import Firebase from "../components/Firebase";

const SearchPage = () => {
    const {search} = useLocation();
    const [cards, setCards] = useState([]);
    const searchParams = new URLSearchParams(search);

    useEffect(() => {
        // fetch(window.$server + `/search/${search}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         let newCards = [];
        //         for (let element of data.searchResults) {
        //             newCards.push(<CardComponent key={element.id} className={'searchCard'} data={element}/>)
        //         }
        //         setCards(newCards)
        //     };
        const params = new URLSearchParams(search);
        const q = params.get('q');

        document.title = "Search - " + q;

        async function searchCards() {

            const casSearch = query(collection(Firebase, "cards"), where("cas", "==", q.toLowerCase()));
            const titleSearch = query(collection(Firebase, "cards"), where("titleLower", "==", q.toLowerCase()));
            const casSearchSnapshot = await getDocs(casSearch);
            const titleSearchSnapshot = await getDocs(titleSearch);

            let newCards = []
            casSearchSnapshot.forEach((doc) => {

                newCards.push(<CardComponent key={doc.data().id} className={'searchCard'} data={doc.data()}/>)
            })

            titleSearchSnapshot.forEach((doc) => {
                newCards.push(<CardComponent key={doc.data().id} className={'searchCard'} data={doc.data()}/>)
            })

            setCards(newCards);
        }

        searchCards().catch((err) => console.log(err));
    }, [search])

    return (
        <Fragment>
            <div className="container border my-3">
                <h3 className={styles.searchResultsText}>{cards.length === 0 ? 'No' : 'Search'} results for
                    <span className={styles.searchQueryHeading}>{' ' + searchParams.get('q')}</span>
                </h3>
                <div className={`${styles.cardResults} container flex-wrap`}>
                    {cards}
                </div>
            </div>
        </Fragment>
    )
}

export default SearchPage;