import {Fragment} from "react";
import TopProducts from "../components/TopProducts";

const HomePage = () => {

    document.title = "RVS Pharma - Home";
    return (
        <Fragment>
            <TopProducts/>
        </Fragment>
    )
}

export default HomePage;